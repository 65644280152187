import '../../styles/_thumbnail.scss';

import Cookies from 'js-cookie';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';

import { Slide } from '../../datasource/generated';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import Thumbnail from '../Thumbnail/Thumbnail';
import ThumbnailLGImage from './assets/ThumbnailLG.png';
import ThumbnailMDImage from './assets/ThumbnailMD.png';
import ThumbnailXLGImage from './assets/ThumbnailXLG.png';


export const sockets = [
    'bottom-right',
    'top-right',
    'top-left',
    'bottom-left',
];

const hideDocks = [
    'center-left',
    'center-right',
];

export interface IThumbnailContainerProps {
    currentSlide?: Slide | null,
    thumbnailPosition?(activeSocket: string): void;
    videoRef: React.MutableRefObject<HTMLVideoElement | undefined> | React.RefObject<HTMLVideoElement | undefined>;
    showSlides?: boolean;
}

export const ThumbnailContainer = ({
    currentSlide,
    thumbnailPosition,
    videoRef,
    showSlides,
}: IThumbnailContainerProps) => {
    const [isSwapped, setIsSwapped] = useState(true);

    const [selectionMode, setSelectionMode] = useState(false);
    const [activeSocket, setActiveSocket] = useState(Cookies.get('activeSocket') || 'bottom-right');
    const [activeHidingDock, setActiveHidingDock] = useState(Cookies.get('activeHidingDock') || '');
    const [hideMainSockets, setHideMainSockets] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const isSmallScreen = useMediaQuery(ScreenSizeQueries.SMALL);
    const isMediumScreen = useMediaQuery(ScreenSizeQueries.MEDIUM);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const isXLargeScreen = useMediaQuery(ScreenSizeQueries.XLARGE);

    const ghostImg = new Image();
    ghostImg.src = isMediumScreen
        ? ThumbnailMDImage
        : isLargeScreen && !isXLargeScreen
            ? ThumbnailLGImage
            : ThumbnailXLGImage;

    const cookieOptions = useMemo(() => ({
        sameSite: 'strict',
        expires: 365
    }), []);

    const handleSetActiveSocket = useCallback((newSocket: string) => {
        Cookies.set('activeSocket', newSocket, cookieOptions);
        Cookies.set('activeHidingDock', '', cookieOptions);
        setActiveSocket(newSocket);
        setActiveHidingDock('');
    }, [cookieOptions]);

    const handleSetActiveHidingDock = useCallback((newHidingDock: string) => {
        Cookies.set('activeHidingDock', newHidingDock, cookieOptions);
        setActiveHidingDock(newHidingDock);
    }, [cookieOptions]);

    useLayoutEffect(() => {
        thumbnailPosition?.(activeSocket || activeHidingDock);
    }, [activeSocket, thumbnailPosition, activeHidingDock ]);

    useUpdateEffect(() => {
        setIsSwapped(showSlides || false);
    }, [showSlides]);

    useEffect(() => {
        if (activeHidingDock && activeSocket) {
            setActiveSocket('');
        }

    }, [activeHidingDock, activeSocket]);
    
  
    const handleOnDragStart = (e: React.DragEvent, socket: string) => {
        setSelectionMode(true);
        e.dataTransfer.setData('socket', socket);

        //get coordinates
        const rect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        e.dataTransfer.setDragImage(ghostImg, mouseX, mouseY);

        e.currentTarget.classList.remove('drag-end', 'drag-leave', 'dragging');
        e.currentTarget.classList.add('drag-start');
    };

    const handleOnDrop = (e: React.DragEvent, target: string, isHideDock: boolean) => {
        isHideDock ? handleSetActiveHidingDock(target) : handleSetActiveSocket(target);
        setSelectionMode(false);
        e.currentTarget.classList.remove('drag-end', 'drag-leave', 'dragging');
        setHideMainSockets(false);
    };

    const handleOnDragOver = (e: React.DragEvent) => {
        setHideMainSockets(
            e.currentTarget.classList.contains('center-right') ||
                e.currentTarget.classList.contains('center-left')
        );

        e.preventDefault();
        e.currentTarget.classList.remove(
            'drag-end',
            'drag-leave',
            'drag-start'
        );
        e.currentTarget.classList.add('dragging');
    };

    const handleDragLeave = (e: React.DragEvent) => {
        setHideMainSockets(false);
        e.preventDefault();
        e.currentTarget.classList.remove('dragging', 'drag-end', 'drag-start');
        e.currentTarget.classList.add('drag-leave');
    };

    const handleDragEnd = (e: React.DragEvent) => {
        e.preventDefault();
        e.currentTarget.classList.remove(
            'dragging',
            'drag-leave',
            'drag-start'
        );
        e.currentTarget.classList.add('drag-end');
        setSelectionMode(false);
    };

    const renderTargets = (targets: string[], isHideDock: boolean) => {
        return targets.map((target) => {
            const isActiveSocket = isHideDock ? activeHidingDock === target : activeSocket === target;
            return (
                <div
                    key={target}
                    className={`thumbnail-container__socket ${
                        !isSmallScreen && selectionMode
                            ? 'thumbnail-container__socket--selection-mode'
                            : ''
                    } ${target} ${
                        isActiveSocket
                            ? 'thumbnail-container__socket--active'
                            : ''
                    }
                    ${
                !isHideDock && hideMainSockets
                    ? 'thumbnail-container__socket--fade-out'
                    : ''
                }`
                    }
                    draggable={!isSmallScreen}
                    onDrop={(e) => handleOnDrop(e, target, isHideDock)}
                    onDragStart={(e) => handleOnDragStart(e, target)}
                    onDragOver={(e) => handleOnDragOver(e)}
                    onDragLeave={handleDragLeave}
                    onDragEnd={handleDragEnd}
                    onDoubleClick={() => setIsSwapped(isSwapped => !isSwapped)}
                >
                    <Thumbnail
                        activeThumbnail={isActiveSocket}
                        currentSlide={currentSlide}
                        socket={target}
                        handleSetTarget={isHideDock ? handleSetActiveSocket : handleSetActiveHidingDock}
                        handleMove={(s: string) => handleSetActiveSocket(s)}
                        handleSwap={() => setIsSwapped(isSwapped => !isSwapped)}
                        isSwapped={isSwapped}
                        videoRef={videoRef}
                        handleShowControls={setShowControls}
                        showControls={showControls}
                    />
                </div>
            );
        });
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className='thumbnail-container'
            onMouseUp={() => setSelectionMode(false)}
        >
            { renderTargets(hideDocks, true) }
            { renderTargets(sockets, false) }
        </div>
    );
};

export default ThumbnailContainer;
