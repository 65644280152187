import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';

import { useGetTranscriptsQuery } from '../datasource/queries/transcripts';
import { useAppSelector } from '.';

export function useFetchTranscripts(delayQueries = false) {
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);

    const mediaInfo = useMemo(() => {
        const segment = launchDetails?.segments?.find(seg => 
            seg.itemSk === currentSegmentItemSk ?? launchDetails?.currentSegment
        );
        return segment ? {
            itemPk: segment.itemPk,
            transcription: segment.video?.transcription,
        } : {
            itemPk: launchDetails.itemPk,
            transcription: launchDetails.video?.transcription,
        };
    }, [currentSegmentItemSk, launchDetails]);

    const shouldFetchTranscripts = useMemo(() => {
        if (launchDetails.isWebcast || delayQueries) {
            return false;
        }

        return mediaInfo?.itemPk && mediaInfo.transcription;
    }, [delayQueries, launchDetails.isWebcast, mediaInfo?.itemPk, mediaInfo?.transcription]);

    const {
        currentData: transcripts = [],
        isLoading,
        isFetching,
    } = useGetTranscriptsQuery((shouldFetchTranscripts ? mediaInfo?.itemPk : null) ?? skipToken);

    return { transcripts, isLoading, isFetching };
}