import '../../styles/_player.scss';

import JWPlayer from '@jwplayer/jwplayer-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDebounceCallback } from 'usehooks-ts';

import { useConnectivity } from '../../contexts/ConnectivityContext';
import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { MenuOptions } from '../../enums/MenuOptions';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import { ICaptionsList, useCaptions } from '../../hooks/useCaptions';
import { useCleRetryHandler } from '../../hooks/useCleRetryHandler';
import { useCleTimer } from '../../hooks/useCleTimer';
import useDownload from '../../hooks/useDownload';
import { useFetchTranscripts } from '../../hooks/useFetchTranscripts';
import { useLiveEndOfProgramTimer } from '../../hooks/useLiveEndOfProgramTimer';
import { MediaLogEventTypes, useLogMediaEvents } from '../../hooks/useLogMediaEvents';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import useMobileSlideToggle from '../../hooks/useMobileSlideToggle';
import { useMux } from '../../hooks/useMux';
import usePlaybackProgress from '../../hooks/usePlaybackProgress';
import {
    IQualityLevels,
    usePlayerSettings,
} from '../../hooks/usePlayerSettings';
import { usePlaylist } from '../../hooks/usePlaylist';
import { useSlides } from '../../hooks/useSlides';
import { useStsTimer } from '../../hooks/useStsTimer';
import { useThrottleCallback } from '../../hooks/useThrottleCallback';
import { useToasts } from '../../hooks/useToasts';
import useTranscriptButton from '../../hooks/useTranscriptButton';
import logger from '../../utility/logger';
import platform from '../../utility/platform';
import IdleOverlay from '../IdleOverlay/IdleOverlay';
import Loader from '../Loader/Loader';
import { OffAir } from '../OffAir/OffAir';
import { menuData } from '../SettingsMenu/MenuData';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import { setLargePanelOpenCommand } from '../Store/menuSlice';
import {
    ExternalCommand,
    ExternalFullscreenToggle,
    setExternalCommand,
    setExternalFullscreenToggle,
    setExternalSeekCommand,
    setPlayerElementReady,
} from '../Store/playerSlice';
import ThumbnailContainer from '../ThumbnailContainer/ThumbnailContainer';
import { ToastContainer } from '../Toast/ToastContainer';
import Transcripts from '../Transcripts/Transcripts';

export interface IPlayerProps {
    autostart?: boolean,
    showThumbnail?: boolean,
    isMenuOpen?: boolean,
}

export interface OnTimeEvent {
    position: number;
    duration: number;
    currentTime: number;
    seekRange?: {
      start: number;
      end: number;
    };
    metadata?: {
      currentTime: number;
    };
    absolutePosition?: number; // Optional since it can be null
    type?: 'time';
    viewable?: 1;
}

export const Player = (
    {
        autostart = false,
        showThumbnail = false,
        isMenuOpen = false,
    }: IPlayerProps) => {

    const user = useAppSelector((state) => state.user);

    const dispatch = useAppDispatch();
    const playerRef = useRef<typeof JWPlayer>(null);
    const videoRef = useRef<HTMLVideoElement>();
    const isInitialPlay = useRef<number | null>(null);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);

    const { logMediaEvent } = useLogMediaEvents();

    const { connected } = useConnectivity();

    const hasLoggedInitialLoad = useRef(false);

    const { playlist, segment } = usePlaylist();
    const { emitVideoChangeEvent, initializeMux } = useMux();
    const { transcripts } = useFetchTranscripts();
    const [currentFilePlaying, setCurrentFilePlaying] = useState('');
    const [thumbnailPosition, setThumbnailPosition] = useState('');
    const didImagesFinishCaching = useAppSelector((state) => state.player.didPosterImageFinishCaching);
    const isFetching = useAppSelector((state) => state.player.isFetchingLaunchDetails);
    const [isMetaDataReady, setIsMetaDataReady] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);

    const { data: clientConfig } = useGetClientConfigQuery();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const [showIdleOverlay, setShowIdleOverlay] = useState(false);
    const [lastInteraction, setLastInteraction] = useState('');
    const [playerElement, setPlayerElement] = useState<HTMLElement | null>(null);
    const [controlBarState, setControlBarState] = useState('default');
    const { pushToDataLayer } = useAnalytics();
    const idleOverlayTimeoutRef = useRef<number>();
    const controlBarTimeoutRef = useRef<number>();

    const isSmallScreen = useMediaQuery(ScreenSizeQueries.JWSMALL);

    const { isCleTimerRunning, startCleTimer, pauseCleTimer } = useCleTimer();
    const { retryFailedTimestamps } = useCleRetryHandler();

    const { isStsTimerRunning, startStsTimer, setIsPlaying } = useStsTimer({
        playerRef: playerRef
    });

    const { setPlaybackProgress, postLatestPlaybackProgress } = usePlaybackProgress();
    useLiveEndOfProgramTimer({
        playerRef: playerRef
    });

    const { showAlertToast } = useToasts();

    const { handleCaptionsList, handleCaptionsChanged, captions } = useCaptions({
        playerRef: playerRef
    });
    const { 
        viewSlides,
        displayToggleSlidesButton, 
        slidesEnabled,
        setSlidesEnabled,
    } = useMobileSlideToggle({
        playerRef: playerRef,
        toggleTranscriptButton: () => onToggleTranscript(false),
    });
    const { showTranscript, onToggleTranscript, displayToggleTranscriptButton, handleTranscriptsButtonRef } = useTranscriptButton(playerRef);
    const { displayDownloadButton } = useDownload();

    const {
        showSettingsMenu,
        displayToggleSettingsButton,
        handleSettingsButtonRef,
        handleCloseSettings,
        settingsButtonRef,
        handleQualityLevels,
        setSettingsMenu,
        settingsMenu,
        qualitySettings,
        setQualitySettings,
        captionsSettings,
        transcriptSettings,
        setTranscriptSettings,
        handleFontSize,
    } = usePlayerSettings({
        playerRef: playerRef,
    });

    const { currentSlide, handleProviderPlayer } = useSlides();
    const externalCommand = useAppSelector((state) => state.player.externalCommand);

    const logBufferingMediaEvent = useDebounceCallback(useCallback((timestamp: number) => {
        logMediaEvent(MediaLogEventTypes.BUFFERING, 'Buffering...', timestamp);
    }, [logMediaEvent]), 5_000);

    const logConnectivityLostEvent = useThrottleCallback(useCallback((timestamp: number) => {
        logMediaEvent(MediaLogEventTypes.CONNECTION_LOST, 'Internet connection lost', timestamp, true);
    }, [logMediaEvent]), 5_000);

    const logConnectivityRestoredEvent = useThrottleCallback(useCallback((timestamp: number) => {
        logConnectivityLostEvent.cancel();
        logMediaEvent(MediaLogEventTypes.CONNECTION_RESTORED, 'Internet connection restored', timestamp);
    }, [logConnectivityLostEvent, logMediaEvent]), 5_000);

    useEffect(() => {
        logMediaEvent(MediaLogEventTypes.PROGRAM_LAUNCH, platform.ua);
    }, [logMediaEvent]);

    const previousConnectedState = useRef(connected);
    useEffect(() => {
        if (previousConnectedState.current !== connected) {
            if (connected) {
                logConnectivityRestoredEvent(Date.now());
            } else {
                logConnectivityLostEvent(Date.now());
            }
        }
        previousConnectedState.current = connected;
    }, [connected, logConnectivityLostEvent, logConnectivityRestoredEvent]);

    useEffect(() => {
        if (playerRef.current) {
            switch (externalCommand) {
                case ExternalCommand.play:
                    playerRef.current.play();
                    dispatch(setExternalCommand(ExternalCommand.noop));
                    break;
                case ExternalCommand.pause:
                    playerRef.current.pause();
                    dispatch(setExternalCommand(ExternalCommand.noop));
                    break;
                case ExternalCommand.noop:
                    break;
            }
        }
    }, [externalCommand, dispatch]);

    useEffect(() => {
        if (launchDetails.isWebcast) {
            if (currentSlide?.slideNumber && currentSlide?.slideNumber > 0) {
                setSlidesEnabled(true);
            } else {
                setSlidesEnabled(false);
            }
        }
    }, [slidesEnabled, currentSlide?.slideNumber, launchDetails.isWebcast, setSlidesEnabled]);

    const externalFullscreenToggle = useAppSelector((state) => state.player.externalFullscreenToggle);

    useEffect(() => {
        if (playerRef.current) {

            switch (externalFullscreenToggle) {
                case ExternalFullscreenToggle.on:
                    playerRef.current.setFullscreen(true);
                    dispatch(setExternalFullscreenToggle(ExternalFullscreenToggle.noop));
                    break;
                case ExternalFullscreenToggle.off:
                    playerRef.current.setFullscreen(false);
                    dispatch(setExternalFullscreenToggle(ExternalFullscreenToggle.noop));
                    break;
                case ExternalFullscreenToggle.noop:
                    break;
            }
        }
    }, [externalFullscreenToggle, dispatch]);

    const externalSeekCommand = useAppSelector((state) => state.player.externalSeekCommand);

    useEffect(() => {
        if (playerRef.current && externalSeekCommand !== undefined) {
            playerRef.current.seek(externalSeekCommand);
            dispatch(setExternalSeekCommand(undefined));
        }
    }, [dispatch, externalSeekCommand]);

    useEffect(() => {
        if (isMenuOpen) {
            clearTimeout(idleOverlayTimeoutRef.current);
            idleOverlayTimeoutRef.current = undefined;
        } 
    }, [isMenuOpen]);

    const displayTitle = useCallback(() => {
        const node = document.querySelector('.jw-spacer');
        const programTitle = document.querySelector('.jwplayer-custom__program-title');
        if (node && !programTitle) {
            const title = document.createElement('div');
            title.textContent = (segment?.title ? segment?.title : launchDetails?.title) || '';
            title.classList.add('jwplayer-custom__program-title', 'paragraph-2');
            node.before(title);
        } else if (programTitle) {
            programTitle.textContent = (segment?.title ? segment?.title : launchDetails?.title) || '';
        }
    }, [launchDetails?.title, segment?.title]);

    useEffect(() => {
        setControlBarState('hidden');
        displayTitle();
        setCurrentFilePlaying(playlist[0]?.file ?? '');

        if (!playerRef.current || !playlist.length)
            return;

        playerRef.current.load(playlist);
        playerRef.current.play();

        if (currentFilePlaying && currentFilePlaying !== playlist[0].file) {
            emitVideoChangeEvent(playerRef, segment!);
        }
    }, [currentFilePlaying, displayTitle, emitVideoChangeEvent, playlist, segment]);

    useEffect(() => {
        if (showSettingsMenu) {
            setControlBarState('forced');
        } else {
            setControlBarState('default');
        }
    }, [showSettingsMenu]);

    useEffect(() => {
        displayToggleSettingsButton();
    }, [captions?.track, captions?.tracks, displayToggleSettingsButton]);

    const swapVolumeAndLive = () => {
        const volumeButton = document.querySelectorAll('.jw-icon-volume')[0];
        const textLive = document.querySelectorAll('.jw-text-live')[0];

        if (volumeButton && textLive) {
            const parentElement = volumeButton.parentElement;

            if (parentElement) parentElement.insertBefore(textLive, volumeButton);
        }
    };

    const setupViews = () => {
        displayTitle();

        if (isSmallScreen && playerRef.current) {
            displayToggleSlidesButton(viewSlides);
        }

        setSettingsMenu(menuData);
        displayToggleSettingsButton();
        displayToggleTranscriptButton(showTranscript);
        displayDownloadButton(playerRef);

        if (launchDetails?.isWebcast) {
            swapVolumeAndLive();
        }
    };

    const onReady = () => {
        setupViews();

        const playerElement = document.getElementById('player');

        if (playerElement) {
            playerElement.addEventListener('mousedown', () => {
                setLastInteraction('mouse');
            });

            playerElement.addEventListener('keyup', () => {
                setLastInteraction('keyboard');
            });

            setPlayerElement(playerElement);

            dispatch(setPlayerElementReady());
        }
        
    };

    const onPlay = (event: any) => {
        logBufferingMediaEvent.cancel();
        clearTimeout(idleOverlayTimeoutRef.current);
        idleOverlayTimeoutRef.current = undefined;

        if (!isCleTimerRunning && (launchDetails?.trackCle && !launchDetails?.isPortable && !user.isGroupcast)) {
            startCleTimer();
        }

        if (!isStsTimerRunning && !user.isGroupcast) {
            startStsTimer();
        }

        setIsPlaying(true);

        if (bufferTimeoutRef.current) {
            clearTimeout(bufferTimeoutRef.current);
            bufferTimeoutRef.current = undefined;
        }
        setIsBuffering(false);

        // 'external' - (JW API usage)
        if (event.playReason === 'external') {
            setControlBarState('forced');
            controlBarTimeoutRef.current = setTimeout(() => {
                setControlBarState('default');
            }, 5000) as unknown as number;
        } else {
            if (controlBarState === 'hidden')
                setControlBarState('default');
        }

        if (isInitialPlay.current == null) {
            pushToDataLayer({ event: 'video_start', additionalParams: { started_from: 'launch' } });
        }

        if (isInitialPlay.current !== currentSegmentItemSk) {
            isInitialPlay.current = currentSegmentItemSk;
        }
    };

    const onControls = (controls: boolean) => {
        if (controls) {
            displayTitle();
        }
    };

    const onTime = (event: OnTimeEvent) => {
        // https://github.com/jwplayer/jwplayer/issues/3339
        if (playerRef.current.getConfig().scrubbing) return;

        if (!hasLoggedInitialLoad.current) {
            logMediaEvent(MediaLogEventTypes.START_VIDEO, 'on play (first time)');
            hasLoggedInitialLoad.current = true;
        }

        setPlaybackProgress({ position: event.position, shouldTrackVideoProgress: true, duration: event.duration });
    };

    const onSeek = (event: any) => {
        // https://github.com/jwplayer/jwplayer/issues/3339
        if (playerRef.current.getConfig().scrubbing) return;

        setPlaybackProgress({ position: event.offset, postImmediately: true });
    };

    const onError = (event: any) => {
        showAlertToast(event);

        handlePlaybackStopped(event);

        logMediaEvent(MediaLogEventTypes.ERROR, `VideoPlayer error: [${event.code} - ${event.type}] ${event.message}`);
    };

    const bufferTimeoutRef = useRef<number>();

    const handlePlaybackStopped = (event: any) => {
        if (launchDetails.isWebcast && event.type === 'pause') {
            playerRef.current.play();
            return;
        } 

        if (!idleOverlayTimeoutRef.current && lastInteraction === 'mouse' && event.pauseReason === 'interaction' && !isMenuOpen) {
            idleOverlayTimeoutRef.current = setTimeout(() => {
                setShowIdleOverlay(true);
            }, 15 * 1000) as unknown as number;
        }

        if (isCleTimerRunning && event.type !== 'buffer') {
            logger.log('Pausing CLE Timer due to JW Player event', {
                individualSk: user.individualSK,
                itemSk: launchDetails.itemSk,
                event: event,
            });
            pauseCleTimer();
        }

        if (isStsTimerRunning && event.type !== 'buffer') {
            setIsPlaying(false);
        }

        if (event.type === 'buffer') {
            logBufferingMediaEvent(Date.now());
            if (bufferTimeoutRef.current) {
                clearTimeout(bufferTimeoutRef.current);
                bufferTimeoutRef.current = undefined;
            }
            bufferTimeoutRef.current = setTimeout(
                () => setIsBuffering(true), 1000) as unknown as number;
        }

        if (event.type === 'complete' && !launchDetails?.isWebcast) {
            logger.log('JW Player Media Complete event', {
                individualSk: user.individualSK,
                itemSk: launchDetails.itemSk,
                event: event,
            });
            pushToDataLayer({ event: 'video_complete' });
            dispatch(setLargePanelOpenCommand(MenuOptions.EndOfVideo));
            retryFailedTimestamps();
        }

        if (event.type === 'complete' || event.type === 'pause') {
            logBufferingMediaEvent.cancel();
            postLatestPlaybackProgress(); 
        }

    };

    const handleIdleOverlayInteraction = useCallback(() => {
        setShowIdleOverlay(false);
    }, []);

    const handleIdleOverlayKeyUp = useCallback((e: KeyboardEvent) => {
        handleIdleOverlayInteraction();
        if (playerRef.current && (e.target === playerElement))
            playerRef.current.play();
    }, [handleIdleOverlayInteraction, playerElement]);

    const handleMetaEvent = useCallback(() => {
        videoRef.current = document.querySelector('video.jw-video') as HTMLVideoElement;
        handleSettingsButtonRef();
        handleTranscriptsButtonRef();

        if (!launchDetails?.isWebcast) {
            const captions: ICaptionsList = {
                tracks: playerRef.current.getCaptionsList(),
                track: playerRef.current.getCurrentCaptions()
            };

            // do not use this for onCaptionsList, because that call fires 
            // multiple times and too early for the current track to be known
            handleCaptionsList(captions);
        }
            
        setIsMetaDataReady(true);
         
    }, [handleTranscriptsButtonRef, handleSettingsButtonRef, launchDetails?.isWebcast, handleCaptionsList]);


    const controlBarClass = useCallback(() => {
        switch (controlBarState) {
            case 'hidden':
                return 'player-wrapper__hide-controlbar';
            case 'forced':
                return 'player-wrapper__force-controlbar';
            default:
                return '';
        }
    }, [controlBarState]);

    const thumbnailPositionClass =
        currentSlide && (currentSlide?.slideNumber ?? 0) > 0 && thumbnailPosition
            ? `thumbnail__${thumbnailPosition}`
            : '';

    const transcriptActiveClass = showTranscript ? 'transcript-active' : '';

    return (
        <div className={`player-wrapper ${controlBarClass()} ${launchDetails?.isWebcast ? 'webcast-overrides' : ''} ${thumbnailPositionClass} ${transcriptActiveClass}`}>
            {
                (playlist.length > 0 && didImagesFinishCaching && !launchDetails.isOffAir) &&
                <JWPlayer
                    id='player'
                    config={{
                        key: clientConfig!.playerKeyJW, captions: {
                            color: captionsSettings.darkBackground === 'Light' ? '000000' : 'FFFFFF',
                            fontSize: captionsSettings.fontSize ? handleFontSize(captionsSettings.fontSize) : 5.25,
                            backgroundColor: captionsSettings.darkBackground === 'Light' ? 'FFFFFF' : '000000',
                            backgroundOpacity: 80
                        }
                    }}
                    playlist={playlist}
                    width='100%'
                    height='100%'
                    autostart={autostart}
                    onReady={onReady}
                    onPlay={onPlay}
                    onPause={handlePlaybackStopped}
                    onBuffer={handlePlaybackStopped}
                    onIdle={handlePlaybackStopped}
                    onComplete={handlePlaybackStopped}
                    onError={onError}
                    onMeta={handleMetaEvent}
                    //onCaptionsList={handleCaptionsList}
                    onCaptionsChanged={handleCaptionsChanged}
                    onTime={onTime}
                    onProviderPlayer={handleProviderPlayer}
                    onSeek={onSeek}
                    onLevels={handleQualityLevels}
                    onLevelsChanged={(currentQuality: IQualityLevels) => setQualitySettings(currentQuality)}
                    pipIcon='disabled'
                    didMountCallback={(obj: typeof JWPlayer) => {
                        playerRef.current = obj.player;
                        initializeMux(playerRef, segment!);
                    }}
                    onControls={onControls}
                />
            }
            {isFetching && <Loader />}
            {isBuffering && <Loader solidBackground={false} />}
            {
                (playlist.length === 0 || !didImagesFinishCaching || isBuffering) &&
                <Loader solidBackground={false} />
            }
            {
                playerElement && (
                    createPortal((
                        <ToastContainer />
                    ),
                    playerElement)
                )
            }
            {
                isMetaDataReady && (showThumbnail || (currentSlide && (currentSlide?.slideNumber ?? 0) > 0)) && playerElement !== null &&
                createPortal(
                    <ThumbnailContainer
                        currentSlide={currentSlide}
                        thumbnailPosition={(position: string) => setThumbnailPosition(position)}
                        videoRef={videoRef}
                        showSlides={viewSlides}
                    />,
                    playerElement
                )
            }
            {
                showIdleOverlay && playerElement !== null &&
                createPortal(
                    <IdleOverlay
                        onKeyUp={handleIdleOverlayKeyUp}
                        onMouseMove={handleIdleOverlayInteraction} />,
                    playerElement
                )
            }
            {
                showTranscript &&
                playerElement !== null &&
                createPortal(
                    <Transcripts
                        transcriptSettings={transcriptSettings} 
                    />,
                    playerElement
                )
            }
            {
                showSettingsMenu &&
                playerElement !== null &&
                createPortal(
                    <SettingsMenu
                        handleClose={handleCloseSettings}
                        isOpen={showSettingsMenu}
                        playerRef={playerRef}
                        settingsButtonRef={settingsButtonRef}
                        hasCaptions={captions?.tracks && captions?.tracks.length > 1 ? true : false}
                        menuData={settingsMenu ?? menuData}
                        qualitySettings={qualitySettings}
                        hasTranscripts={transcripts.length > 0}
                        handleTranscriptSettings={setTranscriptSettings}
                        transcriptSettings={transcriptSettings} 
                    />,
                    playerElement
                )
            }
            {
                launchDetails.isOffAir && 
                <OffAir/>
            }
        </div>
    );
};