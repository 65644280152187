import { apiSlice } from '..';
import { LaunchDetails } from '../generated';

export interface launchDetailsRequest {
    itemSk: number,
    shouldPlacePurchaseForExpiredPrograms: boolean
}

const launchSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        launch: builder.mutation<LaunchDetails, launchDetailsRequest>({
            query: ({ itemSk, shouldPlacePurchaseForExpiredPrograms }) => ({
                url: `/launch-details?itemSk=${itemSk}&shouldPlacePurchaseForExpiredPrograms=${shouldPlacePurchaseForExpiredPrograms}`,
                method: 'POST',
            }),
        })
    })
});

export const { useLaunchMutation } = launchSlice;
export default launchSlice;