import { MutableRefObject, RefObject, useCallback, useEffect,  useState } from 'react';


export const useScrollForMoreDetails = (
    containerRef: MutableRefObject<HTMLDivElement | undefined> | RefObject<HTMLDivElement | undefined> | undefined,
    targetElementRef: MutableRefObject<HTMLParagraphElement | null | undefined>,
    footerElementRef: MutableRefObject<HTMLDivElement | null | undefined>,
    isTargetElementRefReady: boolean,
    isFooterElementRefReady: boolean,
) => {

    const [showScrollForMoreDetails, setShowScrollForMoreDetails] = useState(true);
    const scrollToTargetElement = () => {
        targetElementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [containerClientHeight, setContainerClientHeight] = useState(containerRef?.current?.clientHeight ?? 0);
    const [containerClientWidth, setContainerClientWidth] = useState(containerRef?.current?.clientWidth ?? 0);

    const checkIfTargetElementVisible = useCallback(() => {
        // Show Scroll for more details when target (should be bottom element of container's content) is not completely visible to client
        if (targetElementRef.current && containerRef?.current && footerElementRef.current) {
            const targetTop = targetElementRef.current.offsetTop;
            const targetBottom = targetTop + targetElementRef.current.clientHeight;

            const containerTop = containerRef.current.scrollTop;
            const containerBottom = containerTop + containerRef.current.clientHeight;

            const footerHeight = footerElementRef.current.offsetHeight;

            // Math.ceil and +10 offset to containerBottom value, ensure Continue button will work on unusual display scalings/browser zoom
            if (targetBottom + footerHeight <= Math.ceil(containerBottom) + 10) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }, [containerRef, footerElementRef, targetElementRef]);
    
    useEffect(() => {
        const onResize = () => {
            setContainerClientHeight(containerRef?.current?.clientHeight ?? 0);
            setContainerClientWidth(containerRef?.current?.clientWidth ?? 0);
        };

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [containerRef]);

    useEffect(() => {
        const onScroll = () => setShowScrollForMoreDetails(!checkIfTargetElementVisible());
        const ref = containerRef?.current;

        ref?.addEventListener('scroll', onScroll);

        return () => ref?.removeEventListener('scroll', onScroll);
        // Changes to containerClientHeight and containerClientWidth (upon window resize) may also change scroll status.
    }, [checkIfTargetElementVisible, containerRef, containerClientHeight, containerClientWidth]);

    useEffect(() => {
        // The scroll event (above effect) will not fire if the container is not tall enough to scroll
        // so scrollForMoreDetails must be set on render, once the refs are ready.
        if (isTargetElementRefReady && isFooterElementRefReady) {
            setShowScrollForMoreDetails(!checkIfTargetElementVisible());
        }
        // Changes to containerClientHeight and containerClientWidth (upon window resize) may also change scroll status.
    }, [checkIfTargetElementVisible, isTargetElementRefReady, isFooterElementRefReady, containerClientHeight, containerClientWidth]);


    return {
        showScrollForMoreDetails,
        scrollToTargetElement,
    };
};