import '../../styles/_modal.scss';
import '../../styles/_request-credit-panel.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import LargePanelContext from '../../contexts/LargePanelContext';
import ModalPanelContext from '../../contexts/ModalPanelContext';
import { useSubmitCreditRequestMutation } from '../../datasource/mutations/creditSubmission';
import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { MenuOptions } from '../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import useCpeExams from '../../hooks/useCpeExams';
import { useRequestCredit } from '../../hooks/useRequestCredit';
import { useScrollForMoreDetails } from '../../hooks/useScrollForMoreDetails';
import AuthCode from '../AuthCode/AuthCode';
import Button from '../Button/Button';
import { ErrorContainer, MainError } from '../MainError/MainError';
import ProgramEvaluationPanel from '../ProgramEvaluationPanel/ProgramEvaluationPanel';
import RequestCreditCpeBlock from '../RequestCreditCpeBlock/RequestCreditCpeBlock';
import RequestCreditLicenses from '../RequestCreditLicenses/RequestCreditLicenses';
import { setIsExamStatusVisible, setIsSideBarDisabled, setLargePanelOpenCommand } from '../Store/menuSlice';
import { setEncryptedRegistrationId, setLastSubmittedEncryptedRegistrationId } from '../Store/requestCreditSlice';
import { TimeCards } from '../TimeCards/TimeCards';

export type IRequestCreditPanelProps = {
    onClose: (() => void) | null;
};

const requestCreditSubmitErrorId = 'request-credit-submit-error';

export const RequestCreditPanel = ({ onClose } : IRequestCreditPanelProps) => {

    const dispatch = useAppDispatch();
    const { exams: cpeExams, isGetExamsError } = useCpeExams();
    const isPortable = useAppSelector((state) => state.requestCredit.isPortable);
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);
    const { pushToDataLayer } = useAnalytics();
    const { data: cleSession } = useGetCleSessionQuery(encryptedRegistrationId ?? skipToken);
    
    useEffect(() => {
        dispatch(setIsExamStatusVisible({ RequestCredit : true }));

        return () => {
            dispatch(setIsExamStatusVisible({ RequestCredit : false }));
        };
    }, [dispatch]);

    const { 
        messages, 
        isContinueButtonDisabled, 
        errorMessageElementIds, 
        creditSubmissionProps, 
        isGetLicensesError 
    } = useRequestCredit();
    const [currentStep, setCurrentStep] = useState<'credits' | 'survey'>('credits');

    const [hasTimeCardsLoaded, setHasTimeCardsLoaded] = useState<boolean>(false);

    const [submitCreditRequest, { isError, isLoading }] = useSubmitCreditRequestMutation();

    const [isCreditRequestSubmitError, setIsCreditRequestSubmitError] = useState(false);
    const onClickTimeout = 1600;
    const [isClicked, setIsClicked] = useState<boolean>(false);

    const sendCreditRequest = useCallback(async () => {
        if (!creditSubmissionProps) return;

        setIsCreditRequestSubmitError(false);
        setIsClicked(true);

        try {
            const result = await submitCreditRequest(creditSubmissionProps).unwrap();
            if (result.encryptedRegistrationId) {
                if(cleSession?.watchWmsSfSurveyEnabled) {
                    dispatch(setIsSideBarDisabled(false));
                    dispatch(setLargePanelOpenCommand(MenuOptions.CreditSubmissionConfirmation));
                    pushToDataLayer({ event: 'request_credit_complete', fetchType: FetchType.Program });

                    dispatch(setLastSubmittedEncryptedRegistrationId(creditSubmissionProps.encryptedRegistrationId));
                    dispatch(setEncryptedRegistrationId(result.encryptedRegistrationId));
                    setCurrentStep('survey');

                    setTimeout(() => {
                        setIsClicked(false);
                    }, onClickTimeout);
                }

            } else {
                setIsCreditRequestSubmitError(true);
                setIsClicked(false);
            }
        } catch {
            setIsCreditRequestSubmitError(true);
            setIsClicked(false);
        }
    }, [cleSession?.watchWmsSfSurveyEnabled, creditSubmissionProps, dispatch, pushToDataLayer, submitCreditRequest]);

    const onClickContinueButton = async () => { 
        if (isContinueButtonDisabled) {
            return;
        }
        if (!creditSubmissionProps) {
            return;
        }

        setCurrentStep('survey');

        if (!cleSession?.watchWmsSfSurveyEnabled) {
            // fallback to legacy Salesforce rendered form
            await sendCreditRequest();
        }
    };

    const [isLicensesLoaded, setIsLicensesLoaded] = useState(false);

    const largePanelContainerRef = useContext(LargePanelContext);
    const modalPanelContainerRef = useContext(ModalPanelContext);
    const targetRef = useRef<HTMLParagraphElement | null>(null);
    const footerRef = useRef<HTMLDivElement | null>(null);
    const [isTargetElementRefReady, setIsTargetElementRefReady] = useState(false);
    const [isFooterElementRefReady, setIsFooterElementRefReady] = useState(false);

    const { showScrollForMoreDetails, scrollToTargetElement } =
        useScrollForMoreDetails(largePanelContainerRef ?? modalPanelContainerRef, targetRef, footerRef,
            isTargetElementRefReady, isFooterElementRefReady);

    if (isGetLicensesError || isGetExamsError) {
        return <MainError errorContainer={ErrorContainer.LargePanel}/>;
    }

    if (currentStep === 'survey') {
        return <ProgramEvaluationPanel onSubmit={sendCreditRequest} />;
    }

    return (
        <>
            <div className='watch-modal__content request-credit'>

                <div className='large-panel__heading request-credit__heading'>
                    <h3 className='heading-4 mb-0'>{messages.title}</h3>
                    <p className='paragraph-1 text--medium-grey mb-0'>{messages.subTitle}</p>
                </div>

                <TimeCards requestingCredit={true} onTimeCardsLoaded={setHasTimeCardsLoaded} onClose={onClose} />

                {
                    cpeExams && cpeExams.length > 0 &&
                    <RequestCreditCpeBlock />
                }

                <RequestCreditLicenses onLicensesLoaded={setIsLicensesLoaded} />

                {isPortable && isLicensesLoaded && <AuthCode />}
            
                <p className='paragraph-2 text--medium-grey mb-0' 
                    ref={el => {targetRef.current = el; setIsTargetElementRefReady(true); }}>
                    {messages.acknowledgement}
                </p>
            
            </div>
            <div className='watch-modal__footer'
                ref={el => {footerRef.current = el; setIsFooterElementRefReady(true); }}>
                <div className='watch-modal__footer__button-container'>
                    {
                        (isCreditRequestSubmitError || isError) && 
                        <div className='pb-1'>
                            <i className='watch watch-alert pr-1 text--system-red' aria-hidden={true}/>
                            <span id={requestCreditSubmitErrorId} className='sentence-label text--system-red'>
                                There was a problem processing your request. Please try again later.
                            </span>
                        </div>
                    }
                    <Button 
                        buttonClass={!showScrollForMoreDetails && isContinueButtonDisabled ? 'sticky-button sticky-button--disabled' : 'sticky-button'}
                        label={showScrollForMoreDetails ? 'Scroll for more details' : 'Continue'}
                        aria-label={showScrollForMoreDetails ? 'Scroll for more details' : 'Continue to request credit'}
                        action={showScrollForMoreDetails ? scrollToTargetElement : onClickContinueButton}
                        icon={showScrollForMoreDetails ? 'chevron-down' : 'forward-arrow'}
                        iconPosition={showScrollForMoreDetails ? 'left' : 'right'}
                        isLoading={(!isLicensesLoaded ||!hasTimeCardsLoaded) || isLoading || isClicked}
                        disabled={!isLicensesLoaded || !hasTimeCardsLoaded}
                        aria-describedby={showScrollForMoreDetails ? 
                            '' : 
                            [...errorMessageElementIds, (isError || isCreditRequestSubmitError) ? requestCreditSubmitErrorId : ''].join(' ')
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default RequestCreditPanel;