import '../../styles/_modal.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { MediaLogEventTypes, useLogMediaEvents } from '../../hooks/useLogMediaEvents';
import { AddOrEditLicense } from '../AddOrEditLicense/AddOrEditLicense';
import { DeleteLicense } from '../DeleteLicense/DeleteLicense';
import { LicenseReview } from '../LicenseReview/LicenseReview';
import { ModalPanel } from '../ModalPanel/ModalPanel';
import { setIsLicenseReviewComplete, setIsTutorialComplete } from '../Store/prePlayerSlice';
import { closeLicenseModal } from '../Store/requestCreditSlice';
import { TutorialType } from '../Tutorial/Tutorial';

export const LICENSE_MODAL_ID = 'license-modal';
export const LICENSE_MODAL_HEADING_ID = 'license-modal-heading';

export interface ILicenseModalFocusTarget {
    mode: 'header' | 'card' | 'add-button' | 'edit-button',
    licenseSk?: number,
}

export interface ILicenseModalProps {
    modalAppElement?: string;
    ignoreReview?: boolean;
}
export const LicenseModal = ({
    modalAppElement,
    ignoreReview = false,
}: ILicenseModalProps ) => {
    const dispatch = useAppDispatch();

    const { logMediaEvent } = useLogMediaEvents();

    const isTutorialComplete = useAppSelector((state) => !ignoreReview ? state.prePlayer.isTutorialComplete : true);

    const canGoBackToTutorial = useAppSelector(
        (state) => state.prePlayer.showTutorial.find(x => x.tutorialType == TutorialType.VerifyAttendanceTutorial)?.showTutorial);
    
    const isLicenseReviewComplete = useAppSelector((state) => !ignoreReview ? state.prePlayer.isLicenseReviewComplete : true);

    const requestCreditLicenseModalState = useAppSelector((state) => state.requestCredit.licenseModalState);
    const requestCreditLicenseBeingEdited = useAppSelector((state) => state.requestCredit.licenseBeingEdited);

    const isAudioOnly = useAppSelector((state) => state.player.launchDetails.isAudioOnly ?? false);

    const isModalVisible = (isTutorialComplete && !isLicenseReviewComplete) || (isLicenseReviewComplete && !!requestCreditLicenseModalState);

    const [isAddingOrEditingLicense, setIsAddingOrEditingLicense] = useState<boolean>(false);
    const [isDeletingLicense, setIsDeletingLicense] = useState<boolean>(false);
    const [licenseBeingModified, setLicenseBeingModified] = useState<IndividualCreditAndLicenseInfo | null>(null);
    const [licenseBeingModifiedJurisdictionName, setLicenseBeingModifiedJurisdictionName] = useState<string>();
    const [focusTarget, setFocusTarget] = useState<ILicenseModalFocusTarget>();

    useEffect(() => {
        if (requestCreditLicenseModalState) {
            setIsAddingOrEditingLicense(true);
            if (requestCreditLicenseModalState === 'edit' && requestCreditLicenseBeingEdited) {
                setLicenseBeingModified(requestCreditLicenseBeingEdited?.license);
                setLicenseBeingModifiedJurisdictionName(requestCreditLicenseBeingEdited?.jurisdictionName);
            }
        }
    }, [requestCreditLicenseBeingEdited, requestCreditLicenseModalState]);
    
    const showBackNav = canGoBackToTutorial || isAddingOrEditingLicense || isDeletingLicense;
    const onBackNavPress = useCallback(() => {
        if (isDeletingLicense) {
            setIsDeletingLicense(false);
        } else if (isAddingOrEditingLicense) {
            const target : ILicenseModalFocusTarget = {
                mode: licenseBeingModified ? 'edit-button' : 'add-button',
                licenseSk: licenseBeingModified ? licenseBeingModified.indvStateLicense_SK : undefined,
            };
             
            setIsAddingOrEditingLicense(false);
            setLicenseBeingModified(null);

            if (requestCreditLicenseModalState) {
                dispatch(closeLicenseModal(target));
            }
            else { 
                setFocusTarget(target);
            }
        } else if (canGoBackToTutorial) {
            dispatch(setIsTutorialComplete(false));
        }
    }, [canGoBackToTutorial, dispatch, isAddingOrEditingLicense, isDeletingLicense, licenseBeingModified, requestCreditLicenseModalState]);

    const navigateAfterSave = (savedLicense_SK: number) => {
        setIsAddingOrEditingLicense(false);
        const target : ILicenseModalFocusTarget = { 
            mode: 'card',
            licenseSk: savedLicense_SK,
        };

        if (requestCreditLicenseModalState) {
            dispatch(closeLicenseModal(target));
        }
        else {
            setFocusTarget(target);
        }
    };
    
    const navigateAfterDelete = () => {
        const target : ILicenseModalFocusTarget = {
            mode: 'header',
        };

        setIsAddingOrEditingLicense(false);
        setIsDeletingLicense(false);

        if (requestCreditLicenseModalState) {
            dispatch(closeLicenseModal(target));
        }
        else {
            setFocusTarget(target);
        }
    };

    const onLicenseReviewFinish = () => {
        logMediaEvent(MediaLogEventTypes.PLAYER_SELECTED, isAudioOnly ? 'audio' : 'video');
        dispatch(setIsLicenseReviewComplete(true));
    };

    const onEditLicensePress = (license: IndividualCreditAndLicenseInfo, jurisdictionName?: string) => {
        setLicenseBeingModified(license);
        setIsAddingOrEditingLicense(true);
        setLicenseBeingModifiedJurisdictionName(jurisdictionName);
    };

    const onAddLicensePress = () => {
        setLicenseBeingModified(null);
        setIsAddingOrEditingLicense(true);
    };

    useEffect(() => {
        if (!isAddingOrEditingLicense) {
            setLicenseBeingModified(null);
            setLicenseBeingModifiedJurisdictionName(undefined);
        }
        
    }, [isAddingOrEditingLicense]);

    const key = `${isAddingOrEditingLicense}${isDeletingLicense}`;

    const modalContentRef = useRef<HTMLDivElement>();

    ReactModal.setAppElement(modalAppElement ?? '#root');

    const handleKeyboardKeyUp = useCallback((e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            onBackNavPress();
        }
    }, [onBackNavPress]);

    useEffect(() => {
        if (isModalVisible) {
            window.addEventListener('keyup', handleKeyboardKeyUp, true);
        }

        return () => {
            window.removeEventListener('keyup', handleKeyboardKeyUp, true);
        };
    }, [isModalVisible, handleKeyboardKeyUp]);

    return (
        <ModalPanel 
            id={LICENSE_MODAL_ID}
            key={key}
            isOpen={isModalVisible}
            aria={{
                labelledby: LICENSE_MODAL_HEADING_ID
            }}
            onNavBack={showBackNav ? onBackNavPress : null}
            contentRef={(node) => {modalContentRef.current = node;}}
            shouldReturnFocusAfterClose={false}
        >

            {(!isAddingOrEditingLicense && !isDeletingLicense) &&
                    <LicenseReview 
                        onEditLicensePress={onEditLicensePress}
                        onAddLicensePress={onAddLicensePress}             
                        onFinish={onLicenseReviewFinish}
                        modalRef={modalContentRef}
                        focusTarget={focusTarget}
                    />
            }

            {(isAddingOrEditingLicense && !isDeletingLicense) &&
                    <AddOrEditLicense 
                        license={licenseBeingModified}
                        jurisdictionName={licenseBeingModifiedJurisdictionName}
                        navigateAfterSave={navigateAfterSave}
                        setIsDeletingLicense={setIsDeletingLicense}
                        validateLicenseNumber={isLicenseReviewComplete}  />
            }

            {(isAddingOrEditingLicense && isDeletingLicense && licenseBeingModified != null) &&
                    <DeleteLicense 
                        license={licenseBeingModified}
                        navigateAfterDelete={navigateAfterDelete} />}

        </ModalPanel>
    );
};