import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CreditIconAnimationContext } from '../../contexts/CreditIconAnimationContext';
import { useLaunchMutation } from '../../datasource/mutations/launch';
import { useGetBookmarksByItemSkQuery } from '../../datasource/queries/bookmarks';
import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { useGetGreetingMessageQuery } from '../../datasource/queries/cmsMessages';
import { useGetFacultyByItemSkQuery } from '../../datasource/queries/faculty';
import { useGetLicensesQuery } from '../../datasource/queries/licenses';
import { useGetMaterialsByItemPkQuery } from '../../datasource/queries/materials';
import { useGetProgramCreditDetailsQuery, useGetProgramDetailsQuery } from '../../datasource/queries/programDetails';
import { useGetQuestionsQuery } from '../../datasource/queries/questions';
import { useGetSlidesByItemSkQuery } from '../../datasource/queries/slides';
import { useGetTutorialsQuery } from '../../datasource/queries/tutorials';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useCmsSettings from '../../hooks/useCmsSettings';
import { useFetchTranscripts } from '../../hooks/useFetchTranscripts';
import { usePlayerElement } from '../../hooks/usePlayerElement';
import usePollingIframe from '../../hooks/usePollingIframe';
import { useSignalREvents } from '../../hooks/useSignalREvents';
import platform from '../../utility/platform';
import Audio from '../Audio/Audio';
import CleDialog from '../CleDialog/CleDialog';
import CpeDialogWrapper from '../CpeDialogWrapper/CpeDialogWrapper';
import { LicenseModal } from '../LicenseModal/LicenseModal';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { MainMenu } from '../MainMenu/MainMenu';
import { MobilePromotionCurtain } from '../MobilePromotionCurtain/MobilePromotionCurtain';
import { Player } from '../Player/Player';
import { Redirect } from '../Redirect/Redirect';
import { setSessionGuid as setCleSessionGuid } from '../Store/cleSlice';
import {
    ExternalCommand,
    setExternalCommand,
    setSessionGuid as setPlayerSessionGuid,
} from '../Store/playerSlice';
import { setEncryptedRegistrationId } from '../Store/requestCreditSlice';
import { addAnnouncement } from '../Store/toastSlice';
import { Tutorial, TutorialType } from '../Tutorial/Tutorial';

export const Home = () => {
    const [searchParams] = useSearchParams();
    
    const [showMobilePromotion, setShowMobilePromotion] = useState<boolean | null>(() => {
        if (!['Android', 'iOS'].includes(platform.os())) return false;
        return null;
    });

    const delayQueries = showMobilePromotion !== false;
    
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const user = useAppSelector((state) => state.user);
    const itemSkParam = searchParams.get('itemSk');
    const dispatch = useAppDispatch();
    const itemSk = user.isGroupcast ?
        user.itemSK :
        itemSkParam ? Number.parseInt(itemSkParam) : undefined;
    const getTutorialType = () => {
        if (user.isGroupcast) 
            return TutorialType.GroupcastTutorial;
        if (launchDetails.isPortable) 
            return launchDetails.isAudioOnly ? TutorialType.Mp3Tutorial : TutorialType.Mp4Tutorial;
        return TutorialType.VerifyAttendanceTutorial;
    };

    const [launchProgram,
        { isError: isGetLaunchDetailsError, isLoading: isLoadingLaunchDetails, isSuccess: isGetLaunchDetailsSuccess }
    ] = useLaunchMutation();

    const rid = searchParams.get('rid');
    const { data: cleSession } = useGetCleSessionQuery(rid ?? skipToken);
    useEffect(() => {
        if (rid) {
            dispatch(setEncryptedRegistrationId(rid));
        }
    }, [dispatch, rid]);

    useSignalREvents({ delayQueries });

    useEffect(() => {
        launchProgram({ itemSk: itemSk ?? cleSession?.item_SK ?? 0, shouldPlacePurchaseForExpiredPrograms: false }).unwrap()
            .then(data => {
                setShowMobilePromotion(prev => {
                    if (prev !== null) return prev;
                    else return !data.isPortable;
                });
            });
    }, [itemSk, cleSession?.item_SK, launchProgram]);

    useEffect(() => {
        const sessionGuid = uuidv4();
        dispatch(setCleSessionGuid(sessionGuid));
        dispatch(setPlayerSessionGuid(sessionGuid));
    }, [dispatch]);

    const {
        isLoading: isLoadingClientConfig,
        isSuccess: isGetClientConfigSuccess,
        isError: isGetClientConfigError
    } = useGetClientConfigQuery(delayQueries ? skipToken : undefined);

    const {
        isLoading: isLoadingTutorials,
        isError: isGetTutorialsError,
    } = useGetTutorialsQuery(delayQueries ? skipToken : undefined);

    const {
        isLoadingSettings,
    } = useCmsSettings(delayQueries);

    const {
        isLoading: isLoadingSlides,
    } = useGetSlidesByItemSkQuery(delayQueries || !launchDetails?.itemSk
        ? skipToken
        : {
            itemSk: launchDetails?.itemSk ?? 0,
            current: launchDetails?.isWebcast ?? false
        });

    useFetchTranscripts(delayQueries);

    useEffect(() => {
        document.title = `Practising Law Institute CLE Now - ${launchDetails?.title ?? ''}`;
    }, [launchDetails]);

    const isLicenseReviewComplete = useAppSelector((state) => state.prePlayer.isLicenseReviewComplete);
    
    const { focusPlayerElement, isPlayerElementReady } = usePlayerElement();
    useEffect(() => {
        if (isLicenseReviewComplete && isPlayerElementReady) {
            dispatch(setExternalCommand(ExternalCommand.play));
            focusPlayerElement();
        }
    }, [dispatch, isLicenseReviewComplete, focusPlayerElement, isPlayerElementReady]);

    const [cioIframe, setCioIframe] = useState<React.JSX.Element | undefined>();
    const [animateCreditIcon, setAnimateCreditIcon] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    usePollingIframe(setCioIframe);

    const cleChime = useRef<HTMLAudioElement>(null);

    //DELETE when no longer needed (shows Thumbnail if '&showThumbnail=true' is in query string)
    const urlParams = new URLSearchParams(window.location.search);
    const showThumbnail = urlParams.get('showThumbnail') === 'true';

    const isLoading = delayQueries || isLoadingLaunchDetails || isLoadingClientConfig ||
        isLoadingTutorials || isLoadingSettings || isLoadingSlides;

    const handleAnimateCreditIcon = () => {
        setAnimateCreditIcon(true);
        setTimeout(() => {
            setAnimateCreditIcon(false);
        }, 3500);
    };

    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const { data: greetingMessageBlock } = useGetGreetingMessageQuery(delayQueries ? skipToken : undefined);
    useEffect(() => {
        if (greetingMessageBlock) {
            dispatch(addAnnouncement({
                contentType: 'html',
                htmlContent: greetingMessageBlock.greetingMessage ?? '',
                iconName: 'alert',
                needsReceiptUpdate: false,
            }));
        }
    }, [dispatch, greetingMessageBlock]);

    // Load side panel queries
    useGetFacultyByItemSkQuery(delayQueries || !launchDetails.itemSk 
        ? skipToken : launchDetails.itemSk);
    useGetBookmarksByItemSkQuery(delayQueries || !launchDetails.itemSk 
        ? skipToken : launchDetails.itemSk);
    useGetProgramDetailsQuery(delayQueries || !launchDetails.itemPk 
        ? skipToken : launchDetails.itemPk);
    useGetQuestionsQuery(delayQueries || !launchDetails.itemSk 
        ? skipToken : launchDetails.itemSk);
    useGetMaterialsByItemPkQuery(delayQueries || !launchDetails.itemPk 
        ? skipToken : launchDetails.itemPk);
    useGetProgramCreditDetailsQuery(delayQueries || (!launchDetails.onDemandItemPk || !launchDetails.itemPk)
        ? skipToken : 
        launchDetails?.onDemandItemPk ? launchDetails.onDemandItemPk : launchDetails?.itemPk);
    useGetLicensesQuery(delayQueries || !encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    if (showMobilePromotion)
        return <MobilePromotionCurtain onContinueWatching={() => setShowMobilePromotion(false)} />;

    return (
        <>
            {isLoading && <Loader />}

            {isGetLaunchDetailsError && (
                <MainError noHelpInfo={!itemSk} errorContainer={ErrorContainer.Standalone}>
                    <p className="heading-4">Launch details error occurred</p>
                </MainError>
            )}

            {isGetClientConfigError && (
                <MainError noHelpInfo errorContainer={ErrorContainer.Standalone}>
                    <p className="heading-4">Client configuration error occurred</p>
                </MainError>
            )}

            {launchDetails?.externalUrl && <Redirect url={launchDetails.externalUrl} />}

            {(isGetLaunchDetailsSuccess &&
                isGetClientConfigSuccess &&
                !launchDetails?.externalUrl &&
                !isLoading) && (
                <div
                    className='container-fluid d-flex flex-nowrap p-0 overflow-hidden position-relative'
                    style={{ height: '100%' }}
                >
                    <Tutorial type={getTutorialType()}
                        useContentFromApi={!isGetTutorialsError}
                        onTutorialFinish={() => undefined}
                    />

                    <LicenseModal />

                    <Audio file={require('../Audio/assets/chime.mp3')} ref={cleChime} />
                    <CleDialog chime={cleChime} animateCreditIcon={() => handleAnimateCreditIcon()}/>

                    <CpeDialogWrapper />

                    <div className='col p-0 d-flex flex-grow-1'>
                        <Player
                            showThumbnail={showThumbnail}
                            isMenuOpen={isMenuOpen}
                        />
                        <div className='col-4 d-none panel-sm'></div>
                        <div className='col-3 d-none secondary'></div>
                    </div>
                    <CreditIconAnimationContext.Provider value={animateCreditIcon}>
                        <MainMenu setIsMenuOpen={(open => setIsMenuOpen(open))}/>
                    </CreditIconAnimationContext.Provider>

                    {cioIframe && cioIframe}
                </div>)
            }
        </>
    );
};

export default Home;
