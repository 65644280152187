import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ItemData, LaunchSegment } from '../datasource/generated';
import { IFacultiesItemDataUrlProps, IItemDataUrlProps, useLazyGetFacultiesItemDataByIndividualSksQuery,useLazyGetProgramsItemDataByItemPksQuery, useLazyGetPublicationsItemDataByItemPksQuery } from '../datasource/queries/itemData';
import { store } from '../stories/Store/configureStore';
import { PossibleThreshold, setProgressTracked } from '../stories/Store/playerSlice';
import { getUID, ItemDataLayer, MapItemDataToItemDataLayer } from '../utility/dataLayerUtils';
import logger from '../utility/logger';

type EventType = 
    // Video Playback Events
    'video_start' | 'video_complete' | 'video_progress' |

    // Watch Navigation
    'player > credit_button' | 'player > interact' | 'player > faculty' |
    'player > segments' | 'player > schedule' | 'player > notes' |
    'player > support' | 'player > qa' |

    // Segment interaction / Purchase
    'view_segment_details' | 'segment_thumbnail' |

    // Materials, Faculty, Notes, Support
    'view_faculty' | 'player_create_bookmark' | 'qa_submit' | 'visit_help' | 'support_submit' |

    // Player Controls
    'player_cc' | 'view_transcript' | 'thumb_switch' | 'thumb_move' | 'thumb_minimize' |

    // Pre-Player and Credit Request
    'request_credit_start' | 'request_credit_complete' | // track at program level instead of at segment level
    
    // ECommerce 
    'add_to_cart' | 'begin_checkout' | 'purchase' | 'zero_purchase_value' |
    
    // Launch
    'launch_a_program';

interface PushToDataLayerProps { 
    state?: ReturnType<typeof store.getState> | null,
    event: EventType, 
    additionalParams?: object,   
    skipBaseEventParameters?: boolean,
    fetchType?: FetchType, 
    value?: number,
}

interface PerformZeroDollarPurchaseGAProps {
    state?: ReturnType<typeof store.getState> | null,
    segmentItemSk?: number,
}

interface GetItemsProps {
    state?: ReturnType<typeof store.getState> | null,
    fetchType?: FetchType, 
    value?: number,
}

export enum FetchType {
    Segment,
    Program,
    Publication,
    Faculty,
  }

const defaultCurrency = 'USD';

export const useAnalytics = () => {
    const dispatch = useDispatch();
    const [getProgramsItemDataByItemPks] = useLazyGetProgramsItemDataByItemPksQuery();
    const [getPublicationsItemDataByItemPks] = useLazyGetPublicationsItemDataByItemPksQuery();
    const [getFacultiesItemDataByItemPks] = useLazyGetFacultiesItemDataByIndividualSksQuery(); 

    // fetchType is optional. Default to segment. Values: Segment | Program | Publication | Faculty
    //                        if fetchType is Segment and value is undefined, it uses currentSegmentPk
    //                        if fetchType is Program and value is undefined, it uses launchDetails.itemPk
    //                        if fetchType is Faculty so value (individualSk) is required
    //                        if fetchType is Publication so value (publicationItemPk) is required
    // value is optional. Values: segmentItemSk (segments, use this option only if we want to get items away from current segment) |
    //                            programItemPk (program, use this option only if we want to get items from another external program)  | 
    //                            individualSk (faculties, use this option to retrieve the faculty item) |
    //                            publicationItemPk (publication, use this option to retrieve the publication item)
    const getItems = useCallback(async ({ state: providedState = null, fetchType, value }: GetItemsProps) => {
        const state = providedState ?? store.getState();      
        const launchDetails = state.player.launchDetails;
        let currentSegmentItemSk: number | undefined;
        let segment: LaunchSegment | undefined;
        let segmentItemPk: string | undefined | null;
        let programItemPk: string | undefined | null;
        let publicationItemPk: string | undefined | null;
        let individualSk: string | undefined | null;
        let items: ItemDataLayer[] = [];

        switch (fetchType) {
            case FetchType.Faculty:
                individualSk = value?.toString();            
                if (individualSk) {
                    items = await GetFacultiesItemsByIndividualSks([individualSk]) as ItemDataLayer[];
                    if (items.length === 0) {
                        logger.error(`Fetching faculties items returned empty. Faculty individualSk: ${individualSk}`);
                    }
                }
                else {
                    logger.error('Fetching faculties items returned empty. Faculty individualSk is undefined');
                }

                break;
            case FetchType.Publication:
                publicationItemPk = value?.toString();
                if (publicationItemPk) {
                    items = await GetPublicationsItemsByItemPks([publicationItemPk]) as ItemDataLayer[];
                    if (items.length === 0) {
                        logger.error(`Fetching publications items returned empty. Publication itemPk ${publicationItemPk}`);
                    }
                }
                else {
                    logger.error('`Fetching publications items returned empty. Publication itemPk is undefined');
                }
    
                break;
            case FetchType.Program:
                programItemPk = value?.toString() ?? launchDetails.itemPk;
                if (programItemPk) {
                    items = await GetProgramsItemsByItemPks([programItemPk]) as ItemDataLayer[];
                    if (items.length === 0) {
                        logger.error(`Fetching programs items returned empty. Program itemPk ${programItemPk}`);
                    }
                }
                else {
                    logger.error('`Fetching programs items returned empty. Program itemPk is undefined');
                }

                break;
            case FetchType.Segment:
            default: // Default is by segment, also if it is undefined
                currentSegmentItemSk = value ?? state.player.currentSegmentItemSk ?? launchDetails?.currentSegment;

                segment = launchDetails?.segments?.find(
                    (seg) => seg.itemSk === currentSegmentItemSk); 
  
                segmentItemPk = segment?.itemPk;   
                if (segmentItemPk) {
                    items = await GetProgramsItemsByItemPks([segmentItemPk]) as ItemDataLayer[];
                    // Items can be [] on segments, 1st segment (Opening remarks), we mock the Item array --> no error log to report
                }
                else {
                    logger.error('Fetching segments items returned empty. Segment itemPk is undefined');
                }
                
                break;
        }
    
        return items;

        const cacheValue = true; // Set cache value for GetProgramsItemsByItemPks | GetPublicationsItemsByItemPks | GetFacultiesItemsByIndividualSks
        
        async function GetProgramsItemsByItemPks(itemPks: string[]) {
            if (!itemPks?.filter(Boolean)?.length) { // Avoid to execute it
                logger.error('Skip fetching programs items due `itemPks` is null | undefined | empty.', undefined, false);

                return [];
            }
            try {
                const itemDataUrlProps: IItemDataUrlProps = {
                    itemPks: itemPks,
                    populatePrice: true,
                    limitPriceCalls: true,
                    retrievePracticeAreas: true,
                    retrieveIndustries: true,
                    retrieveMaster: false,
                    retrieveSeries: false
                };
                const preferCacheValue = cacheValue;
                const itemsData = await getProgramsItemDataByItemPks(itemDataUrlProps, preferCacheValue).unwrap();
                const items = itemsData?.map((itemData: ItemData, index: number | undefined) => {
                    return MapItemDataToItemDataLayer(itemData, index);
                });

                return items;
            } catch (error) {
                logger.error('Error fetching programs items:', { error: error }, false);

                return [];
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async function GetPublicationsItemsByItemPks(itemPks: string[]) {
            if (!itemPks?.filter(Boolean)?.length) { // Avoid to execute it
                logger.error('Skip fetching publications items due `itemPks` is null | undefined | empty.', undefined, false);

                return [];
            }
            try {
                const itemDataUrlProps: IItemDataUrlProps = {
                    itemPks: itemPks,
                    populatePrice: true,
                    limitPriceCalls: true,
                    retrievePracticeAreas: true,
                    retrieveIndustries: true,
                    retrieveMaster: false,
                    retrieveSeries: false
                };
                const preferCacheValue = cacheValue;
                const itemsData = await getPublicationsItemDataByItemPks(itemDataUrlProps, preferCacheValue).unwrap();
                const items = itemsData?.map((itemData: ItemData, index: number | undefined) => {
                    return MapItemDataToItemDataLayer(itemData, index);
                });

                return items;
            } catch (error) {
                logger.error('Error fetching publications items:', { error: error }, false);

                return [];
            }
        }

        async function GetFacultiesItemsByIndividualSks(individualSks: string[]) {
            if (!individualSks?.filter(Boolean)?.length) { // Avoid to execute it
                logger.error('Skip fetching faculties items due `individualSks` is null | undefined | empty.', undefined, false);

                return [];
            }
            try {
                const facultiesItemDataUrlProps: IFacultiesItemDataUrlProps = {
                    individualSks: individualSks
                };
                const preferCacheValue = cacheValue;
                const itemsData = await getFacultiesItemDataByItemPks(facultiesItemDataUrlProps, preferCacheValue).unwrap();
                const items = itemsData?.map((itemData: ItemData, index: number | undefined) => {
                    return MapItemDataToItemDataLayer(itemData, index);
                });

                return items;
            } catch (error) {
                logger.error('Error fetching faculties items:', { error: error }, false);

                return [];
            }
        }
    }, [getFacultiesItemDataByItemPks, getProgramsItemDataByItemPks, getPublicationsItemDataByItemPks]);

    const pushToDataLayer = useCallback(async ({ state: providedState = null, event, additionalParams = {}, fetchType = FetchType.Segment, value, skipBaseEventParameters = false }: PushToDataLayerProps) => {       
        const state = providedState ?? store.getState();  // Retrieving the updated state from the store instead of useAppSelector() to avoid unnecessary rerenders
        if ('ecommerce' in additionalParams) { // Clear the previous ecommerce object if it's an ecommerce event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });  
        }
        if (skipBaseEventParameters)
        {
            const obj = {
                event: event,
                ...additionalParams,
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(obj);

            return;
        }
        
        // Populate automatically the base event parameters: video_current_time, video_duration, video_percent, video_provider, video_title, video_url, visible, segment, items + additionalParams
        const launchDetails = state.player.launchDetails;
        const currentSegmentItemSk = state.player.currentSegmentItemSk;
        const currentSegmentProgress = state.player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk);       
        const segment = launchDetails?.segments?.find(
            (seg) => seg.itemSk === currentSegmentItemSk ?? launchDetails?.currentSegment);
        
        let items =  await getItems({ state: state, fetchType: fetchType, value: value }) ?? [];
        if ((items.length == 0) && fetchType == FetchType.Segment) //Mock the item
        {
            const newItem: ItemDataLayer = await mockSegmentItemDataLayer();      
            items = [newItem];
        } 
        // Note: Max 25 parameters per event (GA4 restriction)         
        const obj = {
            event: event,
            video_current_time: currentSegmentProgress?.position ?? 0, // The current timestamp of the video where the viewer is at(in seconds)
            video_duration: currentSegmentProgress?.totalTime, // Video duration in seconds.
            video_percent: Math.floor(((currentSegmentProgress?.position ?? 0) / (currentSegmentProgress?.totalTime ?? 1)) * 100), // The threshold of the video(without the % sign)
            video_provider: 'jw player',
            video_title: segment ? segment.title : launchDetails.title, 
            video_url: segment ? segment.video?.url : launchDetails.video?.url,
            visible: document.hidden ? 0 : 1, // Returns “1” if the player is visible on the screen while the video engagement was tracked         
            segment: segment ? 1 : 0,
            items: items,
            ...additionalParams,
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(obj);               

        return;

        async function mockSegmentItemDataLayer() {
            let newItem: ItemDataLayer = {
                item_id: segment?.itemPk ?? '',
                item_name: segment?.title ?? '',
                price: 0,
                discount: 0,
                item_list_price: 0,
                item_industry: undefined,
                item_industry2: undefined,
                item_industry3: undefined,
                item_industry4: undefined,
                item_industry5: undefined,
                item_practice_area: undefined,
                item_practice_area2: undefined,
                item_practice_area3: undefined,
                item_practice_area4: undefined,
                item_practice_area5: undefined,
                item_parent_id: segment?.prgSegmentSk ? `SEG${segment?.prgSegmentSk}` : undefined
            };
            const newSegment = launchDetails?.segments?.find(
                (seg) => seg.itemSk != currentSegmentItemSk ?? launchDetails?.currentSegment);
            const newSegmentSk = newSegment?.itemSk;
            if (newSegmentSk && !isNaN(Number(newSegmentSk)))
            {
                const newItems = await getItems({ state: state, fetchType: FetchType.Segment, value: Number(newSegmentSk) }) ?? [];
                if (newItems.length > 0) {
                    newItem = { ...newItems[0], ...newItem };
                    return newItem;
                }
            }
            newItem = { ...newItem, currency: defaultCurrency, index: 0, affiliation: 'PLI', item_brand: 'PLI', item_list_id: 'watch', item_list_name: 'watch' };
            
            return newItem;
        }
    }, [getItems]);

    const performZeroDollarPurchaseGA = useCallback(async ({ segmentItemSk, state: providedState = null }: PerformZeroDollarPurchaseGAProps) => {
        const state = providedState ?? store.getState();
        const launchDetails = state.player.launchDetails;
        const segment = launchDetails?.segments?.find(
            (seg) => seg.itemSk === segmentItemSk);

        if (launchDetails.isPromoted && segment?.isPurchased === false) {            
            const items = await getItems({ state: state, fetchType: FetchType.Segment, value: segment?.itemSk }) ?? [];
            if (items?.length) { // For Opening Remarks Segments, getItems is returning []. We do not have them on Pli.edu as variations. We do not sell them --> we do not track any ecommerce event. In any case, segment?.isPurchased is true on those ones
                const source = 'Watch';
                const transaction_prefix = 'WEBZERO';
                const transaction_id = transaction_prefix + getUID(); // Generate fake orderId on zeroDollar orders (unique transactionId). Aprox lenght 55. Max 64 char long by GA4  
                const sumValue = items.find(Boolean)?.price ?? 0;
                const sumDiscount = items.find(Boolean)?.discount ?? 0;
                const currency = items.find(Boolean)?.currency ?? defaultCurrency;
                const value = items.find(Boolean)?.price;
                const coupon = items.find(Boolean)?.coupon;
                const listPrice = items.find(Boolean)?.item_list_price;
                // Ecommerce Events
                await pushToDataLayer({  event: 'add_to_cart', additionalParams:  { ecommerce: { transaction_id: transaction_id, currency: currency, value: value, items: items } }, skipBaseEventParameters: true });
                await pushToDataLayer({  event: 'begin_checkout', additionalParams:  { ecommerce: { transaction_id: transaction_id, currency: currency, value: sumValue, coupon: coupon, items: items } }, skipBaseEventParameters: true });
                await pushToDataLayer({  event: 'purchase', additionalParams:  { purchase_source: source, total_discount: sumDiscount, total_net_price: sumValue, ecommerce: { transaction_id: transaction_id, currency: currency, value: sumValue, coupon: coupon, tax: 0, shipping:0, items: items } }, skipBaseEventParameters: true });
                await pushToDataLayer({  event: 'zero_purchase_value', additionalParams:  { purchase_source: source, currency: currency, value: listPrice, items: items }, skipBaseEventParameters: true });
                // Launch event
                await pushToDataLayer({  event: 'launch_a_program', additionalParams:  { launch_source: source, items: items }, skipBaseEventParameters: true });
            }
        } 
    }, [getItems, pushToDataLayer]);
   
    const trackVideoProgress = ({ position, duration }: { position: number, duration: number }) => {
        const currentProgress = Math.floor((position / duration) * 100);

        switch (currentProgress) {
            case 10:
                trackProgressIfNeeded(10);
                break;
            case 25:
                trackProgressIfNeeded(25);
                break;
            case 50:
                trackProgressIfNeeded(50);
                break;
            case 75:
                trackProgressIfNeeded(75);
                break;
        }
    };

    const trackProgressIfNeeded = (threshold: PossibleThreshold) => {
        const state = store.getState();
        const currentSegmentItemSk = state.player.currentSegmentItemSk;
        const progressTracked = state.player.progressTracked[currentSegmentItemSk] ?? [];

        if (!currentSegmentItemSk) {
            return;
        }

        if (progressTracked.indexOf(threshold) < 0) {
            pushToDataLayer({ event: 'video_progress', state });
            dispatch(setProgressTracked({ itemSk: currentSegmentItemSk, threshold }));
        }
    };

    return { 
        pushToDataLayer,
        trackVideoProgress,
        performZeroDollarPurchaseGA
    };     
};
