import '../../styles/_survey.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useMemo, useState } from 'react';
import { z } from 'zod';

import { SFSurveyResponse } from '../../datasource/generated';
import { ISfSurveySaveProps, useSaveSfSurveyResponseMutation } from '../../datasource/mutations/surveys';
import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { useGetProgramEvaluationSurveyQuery } from '../../datasource/queries/surveys';
import { MenuOptions } from '../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { extractObjectFromFormData } from '../../utility/form';
import logger from '../../utility/logger';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { setIsSideBarDisabled, setLargePanelOpenCommand } from '../Store/menuSlice';
import { SurveySectionExpandable } from './SurveySectionExpandable';
import { isQuestionRequired, surveyContext } from './utils';

export interface IProgramEvaluationSurvey {
    encryptedRegistrationId?: string,
    onSubmit?: () => void;
}

export const ProgramEvaluationSurveyFromWms = ({ encryptedRegistrationId, onSubmit } : IProgramEvaluationSurvey) => {
    const dispatch = useAppDispatch();

    const {
        data: surveyData,
        isError: isError,
        isLoading: isLoading,
        isSuccess: isSuccess
    } = useGetProgramEvaluationSurveyQuery();

    const questions = useMemo(() => {
        return surveyData?.surveySections?.flatMap(sec => sec.surveyQuestions ?? []) ?? [];
    }, [surveyData?.surveySections]);

    const validationSchema = useMemo(() => {
        return z.object({
            question: z.object(Object.values(questions).reduce((schema, question) => {
                let questionSchema: z.ZodType<string | undefined> = z.string({ message: 'This field is required.' });
                if (!isQuestionRequired(question))
                    questionSchema = questionSchema.optional();

                return {
                    ...schema,
                    [`sk_${question.sk}`]: questionSchema,
                };
            }, {} as z.ZodRawShape)),
        });
    }, [questions]);

    const [formErrors, setFormErrors] = useState<z.ZodIssue[]>();

    const user = useAppSelector((state) => state.user);

    const [saveSfSurveyResponse] = useSaveSfSurveyResponseMutation();

    const [isSurveySubmitting, setIsSurveySubmitting] = useState(false);
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);

    const individualSK: number = useAppSelector((state) => state.user.individualSK);
    const { data: clientConfig } = useGetClientConfigQuery();
    const { data: cleSession } = useGetCleSessionQuery(encryptedRegistrationId ?? skipToken);
    
    const finalizeWatchSurveySubmit = () => {
        setTimeout(() => {
            dispatch(setIsSideBarDisabled(false));
            dispatch(setLargePanelOpenCommand(MenuOptions.CreditSubmissionConfirmation));
        }, 1000);
    };


    const formAssemblyLink = `${clientConfig?.formAssemblySiteDomainUrl}?tfa_171=${cleSession?.sourceItem_PK}&tfa_173=${individualSK}&tfa_191=${cleSession?.item_PK}&tfa_220=${cleSession?.registrationID}`;

    async function submitSurvey(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if(isSurveySubmitting) return;

        const form = e.currentTarget;
        const formData = new FormData(form);

        try {
            setFormErrors(undefined);

            const data = validationSchema.parse(extractObjectFromFormData(formData)) as { question: Record<string, string> };
    
            const responses = Object.entries(data.question).map(([ key, value ]): SFSurveyResponse => {
                const [, sk] = key.split('_');
                const question = questions.find(q => q.sk === parseInt(sk));

                const isNumberValue = !Number.isNaN(parseInt(value));

                return {  
                    response: value,
                    responseNumber: isNumberValue ? parseInt(value) : null,
                    responseText: value,
                    surveyQuestion_SK: question?.sk,
                    salesForceSurveyQuestionId: question?.salesForceSurveyQuestionId,
                };
            }).filter(entry => !!entry.response?.length);

            const surveySaveObject: ISfSurveySaveProps = {
                individual_Sk: user.individualSK,
                registrationId: cleSession?.registrationID ?? 0,
                item_Sk: cleSession?.item_SK ?? 0,
                responses,
            };

            try {
                setIsSurveySubmitting(true);
                const response = await saveSfSurveyResponse(surveySaveObject).unwrap();
                if (response) {
                    setIsSurveySubmitting(false);
                    setIsSurveySubmitted(true);
                    onSubmit?.();
                }
            }
            catch {
                setIsSurveySubmitting(false);
                logger.error('Error trying to save survey response: ', surveySaveObject);
            }
               

        } catch (error) {
            if (error instanceof z.ZodError) {
                setFormErrors(error.errors);
                const firstError = error.errors[0];
                const firstErrorInput = form.elements.namedItem(firstError.path.join('.'));
                if(firstErrorInput) {
                    const firstErrorElement = firstErrorInput instanceof RadioNodeList
                        ? firstErrorInput.item(0) as Element
                        : firstErrorInput;
                    const questionElement = firstErrorElement.closest('.survey-question');
                    questionElement?.scrollIntoView( { behavior: 'smooth' });
                }
                
            }
        }
    }

    return (
        <surveyContext.Provider value={{ formErrors: formErrors ?? [] }}>
            <form onSubmit={submitSurvey} noValidate>
                { isLoading &&
                    <div className='h-75'>
                        <Loader />
                    </div>
                }

                {isSuccess && surveyData &&
                <div className={'watch-modal__content program-evaluation-survey'}>
                    <div className='survey__section'>
                        <div>
                            {surveyData.surveySections && surveyData.surveySections.length > 0 ? (
                                surveyData.surveySections.map((section) => (
                                    
                                    <SurveySectionExpandable key={section.sk}
                                        section={section} 
                                    />
                                ))
                            ) :
                                <></>
                            }
                        </div>
                    </div>
                </div>
                
                }


                { isError &&
                    <div className='mt-4 pt-4 mb-4 pb-4 heading-3'>
                        Please <a href={formAssemblyLink} target='_blank' rel='noopener noreferrer'>click here</a> to complete the program evaluation in order to complete your credit request.
                        <div className="watch-modal__footer pt-4">
                            <div className='watch-modal__footer__button-container'> 
                                <Button
                                    type="button"
                                    label={ 'Submit Request' }
                                    buttonClass= 
                                        {'sticky-button program-evaluation-survey__button paragraph-1--bold mt-3'}
                                    typeSubmit={true}
                                    action={finalizeWatchSurveySubmit}
                                    aria-label={'Submit credit request'}
                                />
                            </div>
                        </div>
                    </div>
                }

                { !isError && surveyData &&
                    <div className="watch-modal__footer">
                        <div className='watch-modal__footer__button-container'> 
                            <Button
                                type="submit"
                                label={isSurveySubmitted ? 'Survey submitted' : 'Submit Request' }
                                buttonClass= 
                                    {`sticky-button ${                              
                                        isSurveySubmitted
                                            ? 'sticky-button--success'
                                            : 'program-evaluation-survey__button paragraph-1--bold mt-3'
                                    }`}
                                typeSubmit={true}
                                isLoading={isSurveySubmitting}
                                aria-label={isSurveySubmitted ? 'Survey submitted' : 'Submit credit request'}
                                icon={isSurveySubmitted ? 'checkmark' : undefined}
                                iconPosition={isSurveySubmitted ? 'left' : undefined}
                                isSuccessState={isSurveySubmitted}
                            />
                        </div> 
                    </div>
                }
            </form>
        </surveyContext.Provider>
    );

};


export default ProgramEvaluationSurveyFromWms;