import React, { useEffect, useRef } from 'react';

export interface ICanvasStreamProps extends React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    sourceRef: React.MutableRefObject<HTMLVideoElement | undefined> | React.RefObject<HTMLVideoElement | undefined>;
    sourceOpacity?: number;
}

export const VideoStreamCanvas = ({
    sourceRef,
    sourceOpacity = 0,
    ...props
}: ICanvasStreamProps) => {
    const canvas = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvas.current || !sourceRef.current) return;

        function renderOnCanvas(canvas: HTMLCanvasElement, source: HTMLVideoElement) {
            const container = canvas.parentElement as HTMLElement;
            canvas.width = container.offsetWidth;
            canvas.height = container.offsetHeight;
            canvas.className = [canvas.className, source.className].filter(Boolean).join(' ');
            canvas.style.setProperty('object-fit', window.getComputedStyle(source).getPropertyValue('object-fit'));

            let unmount = false;
            
            const ctx = canvas.getContext('2d');

            let sourceWidth = source.videoWidth;
            let sourceHeight = source.videoHeight;

            source.style.opacity = `${sourceOpacity}`;

            function loop() {
                // If video is not ready, width and height will be 0. Check again when ready.
                if (sourceWidth === 0 || sourceHeight === 0) {
                    sourceWidth = source.videoWidth;
                    sourceHeight = source.videoHeight;
                }

                // We're assuming that the width is the larger than the height of video.
                const shrinkFactor = canvas.height / sourceHeight * 100;
                const dimensions = {
                    width: Math.floor(sourceWidth / 100 * shrinkFactor),
                    height: Math.floor(sourceHeight / 100 * shrinkFactor)
                };
                const offset = (dimensions.width - canvas.width) / 2;

                if (!source.ended) {
                    ctx?.clearRect(0, 0, canvas.width, canvas.height);
                    ctx?.drawImage(source, 0 - offset, 0, dimensions.width, dimensions.height);
                }
                if (unmount)
                    return;

                requestAnimationFrame(loop);
            }
            loop();

            return () => {
                source.style.opacity = '1';
                unmount = true;
            };
        }

        return renderOnCanvas(canvas.current, sourceRef.current);
    }, [sourceRef, sourceOpacity]);
    
    return (
        <canvas {...props} ref={canvas} />
    );
};